import React from 'react'
import {
  Wrap,
  Title,
  SectionHeader,
  SectionBody,
} from '../components/page/components'
import SEO from '../components/SEO'
import GlobalHeader from '../components/global/header'
import GlobalFooter from '../components/global/footer'
import Body from '../components/terms/body'

export default () => {
  return (
    <Wrap>
      <SEO title="Terms" />
      <GlobalHeader hideLogo={true} />
      <SectionHeader>
        <Title>Terms</Title>
      </SectionHeader>
      <SectionBody>
        <Body />
      </SectionBody>
      <GlobalFooter />
    </Wrap>
  )
}
